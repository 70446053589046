import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import * as ACTIONS from './actions/login'
import checkAuth from './Authentication/Auth'
import './config/AxiosConfig';
import './scss/style.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import firebase from 'firebase/app'
import { onMessageListener } from "./firebase";

import ResetPassword from './views/pages/reset-password/ResetPassword';
import { AUTHENTICATE_FAILED } from './constants/types';
import store from './store';
import { ErrorBoundary } from './Error';


const loading = () => (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

// Containers
const TheLayout = lazy(() => import('./containers/TheLayout'));

// Pages
const Login = lazy(() => import('./views/pages/login/Login'));

const ForgotPassword = lazy(() => import('./views/pages/forgot-password/ForgotPassword'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));
const Page500 = lazy(() => import('./views/pages/page500/Page500'));

const token = JSON.parse(localStorage.getItem("authTokenClefill"));
const loggedInUser = JSON.parse(localStorage.getItem("userClefill"));

console.log('firebase.messaging.isSupported: ', firebase.messaging.isSupported());

if (token && loggedInUser) {
    store.dispatch(ACTIONS.authenticate(loggedInUser));
} else {
    store.dispatch({ type: AUTHENTICATE_FAILED });
}

export const App = () => {

    // const [NotificationTrigger, setNotificationTrigger] = useState(false)

    // useEffect(() => {
    //     const abortController = new AbortController()
    //     store.dispatch(ACTIONS.getNotificationCount())

    //     return () => {
    //         abortController.abort();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [NotificationTrigger])

    if (firebase.messaging.isSupported()) {
        onMessageListener().then(payload => {
            console.log('payload: ', payload);
            // setNotificationTrigger(!NotificationTrigger)
            store.dispatch(ACTIONS.getNotificationCount())
            // toastr.info(payload?.notification?.title, payload?.notification?.body)
        }).catch(err => console.log(err));
    }

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ReduxToastr />
            <Suspense fallback={loading()}>
                <ErrorBoundary >
                <Switch>
                    <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
                    <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                    <Route exact path="/reset-password/:resetCode" name="Reset Password" render={props => <ResetPassword {...props} />} />
                    <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                    <Route name="Home" component={checkAuth(TheLayout)} />
                </Switch>
                </ErrorBoundary>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
