import * as ACTION_TYPES from '../constants/types';

const initialState = {
    productList: [],
    productAssignToVehcileList: [],
    stockList: [],
    vehicleList: [],
    productInfo: {},
    error: null,
    showProductList: [],
    productListInfo: {}
}

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                productList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_LIST_ERROR:
            return {
                ...state,
                productList: [],
            }
        case ACTION_TYPES.API_SHOW_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                showProductList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.API_SHOW_PRODUCT_LIST_ERROR:
            return {
                ...state,
                showProductList: [],
            }
        case ACTION_TYPES.GET_PRODUCT_VEHICLE_LIST_S:
            return {
                ...state,
                productAssignToVehcileList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_VEHICLE_LIST_F:
            return {
                ...state,
                productAssignToVehcileList: [],
            }
        case ACTION_TYPES.API_GET_VEHICLE_WITH_QTY_S:
            return {
                ...state,
                vehicleList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.API_GET_VEHICLE_WITH_QTY_F:
            return {
                ...state,
                vehicleList: [],
            }

        case ACTION_TYPES.GET_STOCK_LIST_S:
            return {
                ...state,
                stockList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_STOCK_LIST_F:
            return {
                ...state,
                stockList: [],
            }
        case ACTION_TYPES.GET_PRODUCT_INFO_SUCCESS:
            return {
                ...state,
                productInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_INFO_ERROR:
            return {
                ...state,
                productInfo: {},
            }
            case ACTION_TYPES.GET_PRODUCT_LIST_INFO_SUCCESS:
                return {
                    ...state,
                    productListInfo: action.payload.data,
                    message: action.payload.message,
                    error: null
                }
            case ACTION_TYPES.GET_PRODUCT_LIST_INFO_ERROR:
                return {
                    ...state,
                    productListInfo: {},
                }
        default:
            return state;
    }
}
export default productReducer