export const API = 'API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'

export const SHOW_SPLASH = 'SHOW_SPLASH'
export const HIDE_SPLASH = 'HIDE_SPLASH'

export const LOGIN_S = 'LOGIN_SUCCESS'
export const LOGIN_F = 'LOGIN_FAIL'

// ----------------------------------------------
// LOGIN TYPES
// ----------------------------------------------

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";

export const LOGOUT = "LOGOUT"

export const GET_APP_DATA_SUCCESS = "GET_APP_DATA_SUCCESS";
export const GET_APP_DATA_ERROR = "GET_APP_DATA_ERROR";
export const DATACHANGE = "DATACHANGE"



// ----------------------------------------------
// PRODUCT TYPES
// ----------------------------------------------

//FETCH USER LIST
export const FETCH_ALL_USER_LIST_SUCCESS = "FETCH_ALL_USER_LIST_SUCCESS";
export const FETCH_ALL_USER_LIST_ERROR = "FETCH_ALL_USER_LIST_ERROR";

//PAGE_CHANGE Actions
export const USER_LIST_PAGE_CHANGE = "USER_LIST_PAGE_CHANGE"

//LIMIT_CHANGE Actions
export const USER_LIST_LIMIT_CHANGE = "USER_LIST_LIMIT_CHANGE"

//USER Actions
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//FETCH USER INFO 
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";

//EDIT USER Actions
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_ERROR = "EDIT_USER_ERROR";

//FETCH_PRODUCT Actions
export const FETCH_REGISTRATION_LIST_SUCCESS = "FETCH_REGISTRATION_LIST_SUCCESS";
export const FETCH_REGISTRATION_LIST_ERROR = "FETCH_REGISTRATION_LIST_ERROR";

//USER Actions
export const REJECT_USER_SUCCESS = "REJECT_USER_SUCCESS";
export const REJECT_USER_ERROR = "REJECT_USER_ERROR";

export const CUSTOMER_PLACE_ORDER_SUCCESS = "CUSTOMER_PLACE_ORDER_SUCCESS";
export const CUSTOMER_PLACE_ORDER_ERROR = "CUSTOMER_PLACE_ORDER_ERROR";

// ----------------------------------------------
// CART TYPES
// ----------------------------------------------
//GET CMS LIST
export const GET_CART_DEAILS_SUCCESS = "GET_CART_DEAILS_SUCCESS";
export const GET_CART_DEAILS_ERROR = "GET_CART_DEAILS_ERROR";

// ----------------------------------------------
// CMS TYPES
// ----------------------------------------------

//GET CMS LIST
export const GET_CMS_LIST_SUCCESS = "GET_CMS_LIST_SUCCESS";
export const GET_CMS_LIST_ERROR = "GET_CMS_LIST_ERROR";

//CMS INFO
export const GET_CMS_INFO_SUCCESS = "GET_CMS_INFO_SUCCESS";
export const GET_CMS_INFO_ERROR = "GET_CMS_INFO_ERROR";

//EDIT CMS 
export const EDIT_CMS_SUCCESS = "EDIT_CMS_SUCCESS";
export const EDIT_CMS_ERROR = "EDIT_CMS_ERROR";

/*
Serviceable Area Types
*/
export const GET_AREA_LIST_S = "GET_AREA_LIST_S";
export const GET_AREA_LIST_F = "GET_AREA_LIST_F";

export const GET_ACTIVE_AREA_LIST_S = "GET_ACTIVE_AREA_LIST_S";
export const GET_ACTIVE_AREA_LIST_F = "GET_ACTIVE_AREA_LIST_F";

export const AREA_PAGE_CHANGE = "AREA_PAGE_CHANGE"

export const AREA_LIMIT_CHANGE = "AREA_LIMIT_CHANGE"

export const GET_AREA_INFO_S = "GET_AREA_INFO_S";
export const GET_AREA_INFO_F = "GET_AREA_INFO_F";

export const ADD_AREA_S = "ADD_AREA_S";
export const ADD_AREA_F = "ADD_AREA_F";

export const EDIT_AREA_S = "EDIT_AREA_S";
export const EDIT_AREA_F = "EDIT_AREA_F";

export const DEL_AREA_S = "DEL_AREA_S";
export const DEL_AREA_F = "DEL_AREA_F";

/*
 Vehicle Types
*/
export const GET_VEHICLE_LIST_S = "GET_VEHICLE_LIST_S";
export const GET_VEHICLE_LIST_F = "GET_VEHICLE_LIST_F";

export const VEHICLE_PAGE_CHANGE = "VEHICLE_PAGE_CHANGE"

export const VEHICLE_LIMIT_CHANGE = "VEHICLE_LIMIT_CHANGE"

export const GET_VEHICLE_INFO_S = "GET_VEHICLE_INFO_S";
export const GET_VEHICLE_INFO_F = "GET_VEHICLE_INFO_F";

export const ADD_VEHICLE_S = "ADD_VEHICLE_S";
export const ADD_VEHICLE_F = "ADD_VEHICLE_F";

export const EDIT_VEHICLE_S = "EDIT_VEHICLE_S";
export const EDIT_VEHICLE_F = "EDIT_VEHICLE_F";

export const DEL_VEHICLE_S = "DEL_VEHICLE_S";
export const DEL_VEHICLE_F = "DEL_VEHICLE_F";

export const GET_ACTIVE_DRIVER_S = "GET_ACTIVE_DRIVER_S";
export const GET_ACTIVE_DRIVER_F = "GET_ACTIVE_DRIVER_F";

export const ASSIGN_DRIVER_S = "ASSIGN_DRIVER_S";
export const ASSIGN_DRIVER_F = "ASSIGN_DRIVER_F";

/*Assign PRODUCT To Vehcile */

export const GET_ASSIGN_PRODUCT_LIST_S = "GET_ASSIGN_PRODUCT_LIST_S";
export const GET_ASSIGN_PRODUCT_LIST_F = "GET_ASSIGN_PRODUCT_LIST_F";

export const GET_PRODUCT_SKU_S = "GET_PRODUCT_SKU_S";
export const GET_PRODUCT_SKU_F = "GET_PRODUCT_SKU_F";

export const ADD_PRODUCT_S = "ADD_PRODUCT_S";
export const ADD_PRODUCT_F = "ADD_PRODUCT_F";

export const EDIT_PRODUCT_S = "EDIT_PRODUCT_S";
export const EDIT_PRODUCT_F = "EDIT_PRODUCT_F";

export const DEL_PRODUCT_S = "DEL_PRODUCT_S";
export const DEL_PRODUCT_F = "DEL_PRODUCT_F";

export const ADD_PRODUCT_QUANTITY_S = "ADD_PRODUCT_QUANTITY_S";
export const ADD_PRODUCT_QUANTITY_F = "ADD_PRODUCT_QUANTITY_F";


/*
 Driver Types
*/
export const GET_DRIVER_LIST_S = "GET_DRIVER_LIST_S";
export const GET_DRIVER_LIST_F = "GET_DRIVER_LIST_F";

export const GET_DRIVER_LIST_W_VEHICLE_S = "GET_DRIVER_LIST_W_VEHICLE_S";
export const GET_DRIVER_LIST_W_VEHICLE_F = "GET_DRIVER_LIST_W_VEHICLE_F";

export const GET_DRIVER_INFO_S = "GET_DRIVER_INFO_S";
export const GET_DRIVER_INFO_F = "GET_DRIVER_INFO_F";

export const GET_AVAIABLE_VEHICLES_S = "GET_AVAIABLE_VEHICLES_S";
export const GET_AVAIABLE_VEHICLES_F = "GET_AVAIABLE_VEHICLES_F";

export const ADD_DRIVER_S = "ADD_DRIVER_S";
export const ADD_DRIVER_F = "ADD_DRIVER_F";

export const EDIT_DRIVER_S = "EDIT_DRIVER_S";
export const EDIT_DRIVER_F = "EDIT_DRIVER_F";

export const DEL_DRIVER_S = "DEL_DRIVER_S";
export const DEL_DRIVER_F = "DEL_DRIVER_F";

export const GET_STATE_LIST_S = "GET_STATE_LIST_S";
export const GET_STATE_LIST_F = "GET_STATE_LIST_F";
export const GET_CITY_LIST_S = "GET_CITY_LIST_S";
export const GET_CITY_LIST_F = "GET_CITY_LIST_F";

export const GET_CITIES_LIST_S = "GET_CITIES_LIST_S";
export const GET_CITIES_LIST_F = "GET_CITIES_LIST_F";

/*
 Promo code Types
*/
export const GET_PROMO_CODE_LIST_S = "GET_PROMO_CODE_LIST_S";
export const GET_PROMO_CODE_LIST_F = "GET_PROMO_CODE_LIST_F";

export const GET_PROMO_CODE_INFO_S = "GET_PROMO_CODE_INFO_S";
export const GET_PROMO_CODE_INFO_F = "GET_PROMO_CODE_INFO_F";

export const GET_ALL_CATEGORY_TYPES_S = "GET_ALL_CATEGORY_TYPES_S";
export const GET_ALL_CATEGORY_TYPES_F = "GET_ALL_CATEGORY_TYPES_F";

export const GET_ALL_PRODUCT_TYPES_S = "GET_ALL_PRODUCT_TYPES_S";
export const GET_ALL_PRODUCT_TYPES_F = "GET_ALL_PRODUCT_TYPES_F";

export const GET_ALL_PRODUCTS_S = "GET_ALL_PRODUCTS_S";
export const GET_ALL_PRODUCTS_F = "GET_ALL_PRODUCTS_F";

export const ADD_PROMO_CODE_S = "ADD_PROMO_CODE_S";
export const ADD_PROMO_CODE_F = "ADD_PROMO_CODE_F";

export const EDIT_PROMO_CODE_S = "EDIT_PROMO_CODE_S";
export const EDIT_PROMO_CODE_F = "EDIT_PROMO_CODE_F";

export const DEL_PROMO_CODE_S = "DEL_PROMO_CODE_S";
export const DEL_PROMO_CODE_F = "DEL_PROMO_CODE_F";

export const CHK_PROMO_CODE_LIST_S = "CHK_PROMO_CODE_LIST_S";
export const CHK_PROMO_CODE_LIST_F = "CHK_PROMO_CODE_LIST_F";


/*
 FAQ Types
*/
export const GET_FAQ_LIST_S = "GET_FAQ_LIST_S";
export const GET_FAQ_LIST_F = "GET_FAQ_LIST_F";

export const GET_FAQ_CATEGORY_LIST_S = "GET_FAQ_CATEGORY_LIST_S";
export const GET_FAQ_CATEGORY_LIST_F = "GET_FAQ_CATEGORY_LIST_F";

export const GET_FAQ_INFO_S = "GET_FAQ_INFO_S";
export const GET_FAQ_INFO_F = "GET_FAQ_INFO_F";

export const ADD_FAQ_S = "ADD_FAQ_S";
export const ADD_FAQ_F = "ADD_FAQ_F";

export const EDIT_FAQ_S = "EDIT_FAQ_S";
export const EDIT_FAQ_F = "EDIT_FAQ_F";

export const DEL_FAQ_S = "DEL_FAQ_S";
export const DEL_FAQ_F = "DEL_FAQ_F";

/*
 Sub-Admin Types
*/
export const GET_SUB_ADMIN_LIST_S = "GET_SUB_ADMIN_LIST_S";
export const GET_SUB_ADMIN_LIST_F = "GET_SUB_ADMIN_LIST_F";

export const GET_SUB_ADMIN_S = "GET_SUB_ADMIN_S";
export const GET_SUB_ADMIN_F = "GET_SUB_ADMIN_F";

export const ADD_SUB_ADMIN_S = "ADD_SUB_ADMIN_S";
export const ADD_SUB_ADMIN_F = "ADD_SUB_ADMIN_F";

export const EDIT_SUB_ADMIN_S = "EDIT_SUB_ADMIN_S";
export const EDIT_SUB_ADMIN_F = "EDIT_SUB_ADMIN_F";

export const DEL_SUB_ADMIN_S = "DEL_SUB_ADMIN_S";
export const DEL_SUB_ADMIN_F = "DEL_SUB_ADMIN_F";

/*
 Feedback Types
*/
export const GET_FEEDBACK_LIST_S = "GET_FEEDBACK_LIST_S";
export const GET_FEEDBACK_LIST_F = "GET_FEEDBACK_LIST_F";

export const GET_FEEDBACK_INFO_S = "GET_FEEDBACK_INFO_S";
export const GET_FEEDBACK_INFO_F = "GET_FEEDBACK_INFO_F";

export const UPDATE_STATUS_FEEDBACK_S = "UPDATE_STATUS_FEEDBACK_S";
export const UPDATE_STATUS_FEEDBACK_F = "UPDATE_STATUS_FEEDBACK_F";

/*
 Order Types
*/
export const GET_ORDER_LIST_S = "GET_ORDER_LIST_S";
export const GET_ORDER_LIST_F = "GET_ORDER_LIST_F";

export const GET_ORDER_INFO_S = "GET_ORDER_INFO_S";
export const GET_ORDER_INFO_F = "GET_ORDER_INFO_F";

export const GET_RETURN_ORDER_LIST_S = "GET_RETURN_ORDER_LIST_S";
export const GET_RETURN_ORDER_LIST_F = "GET_RETURN_ORDER_LIST_F";
/*
  Customer Types
*/
export const GET_CUSTOMER_LIST_S = "GET_CUSTOMER_LIST_S";
export const GET_CUSTOMER_LIST_F = "GET_CUSTOMER_LIST_F";

export const GET_CUSTOMER_WITHOUT_ORDER_LIST_S = "GET_CUSTOMER_WITHOUT_ORDER_LIST_S";
export const GET_CUSTOMER_WITHOUT_ORDER_LIST_F = "GET_CUSTOMER_WITHOUT_ORDER_LIST_F";

export const GET_CUSTOMER_INFO_S = "GET_CUSTOMER_INFO_S";
export const GET_CUSTOMER_INFO_F = "GET_CUSTOMER_INFO_F";

export const GET_CUSTOMER_ORDER_LIST_S = "GET_CUSTOMER_ORDER_LIST_S";
export const GET_CUSTOMER_ORDER_LIST_F = "GET_CUSTOMER_ORDER_LIST_F";

export const ADD_NEW_CUSTOMER_S = "ADD_NEW_CUSTOMER_S";
export const ADD_NEW_CUSTOMER_F = "ADD_NEW_CUSTOMER_F";

export const FETCH_CUSTOMER_ADDRESS_SUCCESS = "FETCH_CUSTOMER_ADDRESS_SUCCESS";
export const FETCH_CUSTOMER_ADDRESS_ERROR = "FETCH_CUSTOMER_ADDRESS_ERROR";
// ----------------------------------------------
// PRODUCT CATEGORY TYPES
// ----------------------------------------------

//GET PRODUCT CATEGORY LIST
export const GET_PRODUCT_CATEGORY_LIST_SUCCESS = "GET_PRODUCT_CATEGORY_LIST_SUCCESS";
export const GET_PRODUCT_CATEGORY_LIST_ERROR = "GET_PRODUCT_CATEGORY_LIST_ERROR";

//GET PRODUCT CATEGORY LIST FOR DD
export const GET_PRODUCT_CATEGORY_LIST_DD_SUCCESS = "GET_PRODUCT_CATEGORY_LIST_DD_SUCCESS";
export const GET_PRODUCT_CATEGORY_LIST_DD_ERROR = "GET_PRODUCT_CATEGORY_LIST_DD_ERROR";

//PRODUCT CATEGORY INFO
export const GET_PRODUCT_CATEGORY_INFO_SUCCESS = "GET_PRODUCT_CATEGORY_INFO_SUCCESS";
export const GET_PRODUCT_CATEGORY_INFO_ERROR = "GET_PRODUCT_CATEGORY_INFO_ERROR";

// ----------------------------------------------
// PRODUCT TYPE TYPES
// ----------------------------------------------

//GET PRODUCT TYPE LIST
export const GET_PRODUCT_TYPE_LIST_SUCCESS = "GET_PRODUCT_TYPE_LIST_SUCCESS";
export const GET_PRODUCT_TYPE_LIST_ERROR = "GET_PRODUCT_TYPE_LIST_ERROR";

//GET PRODUCT TYPE LIST FOR DD
export const GET_PRODUCT_TYPE_LIST_DD_SUCCESS = "GET_PRODUCT_TYPE_LIST_DD_SUCCESS";

//PRODUCT TYPE INFO
export const GET_PRODUCT_TYPE_INFO_SUCCESS = "GET_PRODUCT_TYPE_INFO_SUCCESS";
export const GET_PRODUCT_TYPE_INFO_ERROR = "GET_PRODUCT_TYPE_INFO_ERROR";

// ----------------------------------------------
// PRODUCT TYPES
// ----------------------------------------------

//GET PRODUCT DETAILS 
export const GET_PRODUCT_LIST_INFO_SUCCESS = "GET_PRODUCT_LIST_INFO_SUCCESS";
export const GET_PRODUCT_LIST_INFO_ERROR = "GET_PRODUCT_LIST_INFO_ERROR";

export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";
export const GET_PRODUCT_INFO_ERROR = "GET_PRODUCT_INFO_ERROR";


//GET PRODUCT LIST
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";

//GET PRODUCT LIST
export const API_SHOW_PRODUCT_LIST_SUCCESS = "API_SHOW_PRODUCT_LIST_SUCCESS";
export const API_SHOW_PRODUCT_LIST_ERROR = "API_SHOW_PRODUCT_LIST_ERROR";


//GET PRODUCT VEHICLE LIST
export const GET_PRODUCT_VEHICLE_LIST_S = "GET_PRODUCT_VEHICLE_LIST_S";
export const GET_PRODUCT_VEHICLE_LIST_F = "GET_PRODUCT_VEHICLE_LIST_F";

//GET STOCK LIST
export const GET_STOCK_LIST_S = "GET_STOCK_LIST_S";
export const GET_STOCK_LIST_F = "GET_STOCK_LIST_F";
//GET VEHicle Qty LIST

export const API_GET_VEHICLE_WITH_QTY_S = "API_GET_VEHICLE_WITH_QTY_S";
export const API_GET_VEHICLE_WITH_QTY_F = "API_GET_VEHICLE_WITH_QTY_F";


// NOTIFICATION

//Get all notification
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

//Get notification COUNT
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS";
export const GET_NOTIFICATION_COUNT_ERROR = "GET_NOTIFICATION_COUNT_ERROR";

// Dashboard
export const GET_DASHBOARD_ORDER_LIST_S = "GET_DASHBOARD_ORDER_LIST_S";
export const GET_DASHBOARD_ORDER_LIST_F = "GET_DASHBOARD_ORDER_LIST_F";

export const GET_DASHBOARD_DRIVER_LIST_S = "GET_DASHBOARD_DRIVER_LIST_S";
export const GET_DASHBOARD_DRIVER_LIST_F = "GET_DASHBOARD_DRIVER_LIST_F";

export const GET_DASHBOARD_DELIVERED_ORDER_LIST_S = "GET_DASHBOARD_DELIVERED_ORDER_LIST_S";
export const GET_DASHBOARD_DELIVERED_ORDER_LIST_F = "GET_DASHBOARD_DELIVERED_ORDER_LIST_F";


export const CUSTOMER_SEARCH = "CUSTOMER_SEARCH";
export const ORDER_SEARCH = "ORDER_SEARCH";
export const PRODUCT_SEARCH = "PRODUCT_SEARCH";

export const PRODUCT_CATAGOTY_STATE = "PRODUCT_CATAGOTY_STATE";
export const PRODUCT_TYPE_STATE = "PRODUCT_TYPE_STATE";
export const PRODUCT_STATE = "PRODUCT_STATE";
export const ORDER_STATE = "ORDER_STATE";
export const PINCODE_STATE = "PINCODE_STATE";
export const SKU_STATE = "SKU_STATE";


// Referral Discount
export const API_GET_CUSTOMER_ORDER_COUNT = "API_GET_CUSTOMER_ORDER_COUNT";
export const CHECK_AND_GET_REFERRAL_SUCCESS="CHECK_AND_GET_REFERRAL_SUCCESS";
export const CHECK_AND_GET_REFERRAL_ERROR= "CHECK_AND_GET_REFERRAL_ERROR";
export const CHECK_REFERRAL_CODE_SUCCESS="CHECK_REFERRAL_CODE_SUCCESS";
export const CHECK_REFERRAL_CODE_ERROR= "CHECK_REFERRAL_CODE_ERROR";
export const REMOVE_REFERRAL_CODE= "REMOVE_REFERRAL_CODE";
export const ADD_AND_REMOVE_REFERRAL_CODE= "ADD_AND_REMOVE_REFERRAL_CODE";

// VEHICLE Reports 

export const API_GET_VEHICLE_REPORT_S="API_GET_VEHICLE_REPORT_SUCCESS";
export const API_GET_VEHICLE_REPORT_F="API_GET_VEHICLE_REPORT_ERROR";


//Product Rating
export const GET_PRODUCT_RATING_LIST_S = "GET_PRODUCT_RATING_LIST_S";
export const GET_PRODUCT_RATING_LIST_F = "GET_PRODUCT_RATING_LIST_F";
export const PRODUCT_REVIEW_ACCEPT_S = "PRODUCT_REVIEW_ACCEPT_S";
export const PRODUCT_REVIEW_ACCEPT_F = "PRODUCT_REVIEW_ACCEPT_F";
export const PRODUCT_REVIEW_REJECT_S = "PRODUCT_REVIEW_REJECT_S";
export const PRODUCT_REVIEW_REJECT_F = "PRODUCT_REVIEW_REJECT_F";
export const API_ADMIN_PRODUCT_REVIEW_S = "API_ADMIN_PRODUCT_REVIEW_S";
export const API_ADMIN_PRODUCT_REVIEW_F = "API_ADMIN_PRODUCT_REVIEW_F";



