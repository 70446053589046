import moment from "moment";
import { toastr } from "react-redux-toastr";
import { exportPdfAction } from "src/actions/order";

export const removeMobileFomrmatter = no => {
    if (!no)
        return ''
    return no.replace(/[&/\\#,()$~%.'":*?<>{}-]/g, '').replaceAll(' ', '').trim();
}

export const removeCountryCode = no => {
    if (!no)
        return ''
    // return no?.replace('+91', '')?.replaceAll(' ', '')?.trim();
    return no?.substring(0, 3) + "  " + no.substring(3, no.length);

}
export const dateInPast = function (firstDate, secondDate) {
    if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
        return true;
    }
    return false;
};
export const dateDiff = function (firstDate, secondDate) {
    // let tmpreturn = '-'
    if (firstDate && secondDate) {
        // return `${moment(secondDate).diff(moment(firstDate), 'h')} Hours`
        let tmpreturn = moment(secondDate).diff(moment(firstDate), 'h')
        if (tmpreturn < 1) {
            if (moment(secondDate).diff(moment(firstDate), 'minute') < 1) { return `${moment(secondDate).diff(moment(firstDate), 'second')} seconds` }
            else { return `${moment(secondDate).diff(moment(firstDate), 'minute')} minutes` }
        }
        else {
            return `${tmpreturn} hours`
        }
    }
    return '-';
};

export const dateFormatter = (date) => {
    if (date)
        return moment(new Date(date)).format('DD-MM-yyyy')
    else
        return ''
}
export const PromodateFormatter = (date) => {
    if (date)
        return moment(new Date(date)).toISOString()
    else
        return ''
}
export const timeFormatter = (date) => {
    if (date)
        return `(${moment(new Date(date)).format('HH:mm')})`
    else
        return ''
}

//To make lookup from list of data for dropdown selection
export const createLookUp = (list = [], labelParam, optionParam, placeholder) => {

    if (list.length > 0) {
        return [{ label: `Select ${placeholder}`, option: false }, ...list.map((val) => { return { label: val[labelParam], option: val[optionParam] } })]
    }
    else {
        return [{ label: 'No Data Available', option: false }]
    }
};

export const createLookUpWithValue = (list = [], labelParam, optionParam, placeholder) => {

    if (list.length > 0) {
        return [ ...list.map((val) => { return { label: val[labelParam], option: val[optionParam] } })]
    }
    else {
        return [{ label: 'No Data Available', option: false }]
    }
};

export const verficationStatus = [{ label: "Done", value: "Done" },
{ label: "In-Progress", value: "In-Progress" },]

//To generate unique browser id for login
export const uniqueidGenerator = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r && 0x3 | 0x8);
        return v.toString(16);
    });
}

//To generate percentage
export const getDriverCompletionRate = (total, accepted) => {
    let t = Number(total);
    let a = Number(accepted);
    let tmp = (a * 100) / (t)
    return `${parseFloat(tmp).toFixed(2)} %`
}

export const utcDateFormat = (date) => {
    var dateobj = new Date(date);
    let newDate = dateobj.toISOString()
    return moment(newDate).utc().format();
}
export const priceFunction = (price) => {
    if (price) {
        let number = Number(price)
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    }
    return '₹00.00'
}
export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "web";
};
export const tableData = [
    {
        createdAt: "2022-01-11T15:29:16.584Z",
        email: "fayssal_1987@hotmail.com",
        firstName: "Fayssal",
        hotelId: null,
        lastName: "Boughanbou",
        phoneNumber: "8177777777",
        profilePicture: null,
        updatedAt: "2022-01-11T15:29:16.584Z",
        totalOrder: 20,
        normalOrder: 12,
        returnOrder: 21,
        type: 'Bathroom Cleaner',
        status: 'active',
        roles: 'admin',
        _id: "61dda24c5a14be5930e09017",
    },
    {
        createdAt: "2022-01-03T05:08:25.395Z",
        email: "benjenmanderly.894555@gmail.com",
        firstName: "Ben",
        hotelId: null,
        lastName: "Jen",
        phoneNumber: "8652455336",
        profilePicture: null,
        updatedAt: "2022-01-03T05:08:25.395Z",
        totalOrder: 20,
        normalOrder: 12,
        returnOrder: 21,
        type: 'Bathroom Cleaner',
        status: 'active',
        roles: 'admin',
        _id: "61d284c95a14be5930e08fc4",
    },
    {
        createdAt: "2021-12-31T03:10:08.592Z",
        email: "daenerystarly.689170@gmail.com",
        firstName: "Tormund",
        hotelId: null,
        lastName: "Reed",
        phoneNumber: "4155552167",
        profilePicture: null,
        updatedAt: "2021-12-31T03:10:08.592Z",
        totalOrder: 20,
        normalOrder: 12,
        returnOrder: 21,
        type: 'Bathroom Cleaner',
        status: 'active',
        roles: 'admin',
        _id: "61ce74905a14be5930e08fa3",
    }
]

export const onExportPdf = (dispatch, args, fileName) => {
    dispatch(exportPdfAction({ ...args, fileName }))
        .then((res) => window.open(`${res?.data?.url}`, '_blank'))
        .catch((err) => toastr.error('Error', err.message))
}