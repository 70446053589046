import * as ACTION_TYPES from '../constants/types';

const initialState = {
    orderList: [],
    returnOrderList: [],
    orderInfo: {},
    error: null
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_ORDER_LIST_S:
            return {
                ...state,
                orderList: action.payload.data.list? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ORDER_LIST_F:
            return {
                ...state,
                orderList: [],
            }
        case ACTION_TYPES.GET_RETURN_ORDER_LIST_S:
            return {
                ...state,
                returnOrderList: action.payload.data.list? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_RETURN_ORDER_LIST_F:
            return {
                ...state,
                returnOrderList: [],
            }
        case ACTION_TYPES.GET_ORDER_INFO_S:
            return {
                ...state,
                orderInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ORDER_INFO_F:
            return {
                ...state,
                orderInfo: {},
            }
        default:
            return state;
    }
}
export default orderReducer