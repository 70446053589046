import { removeMobileFomrmatter } from "src/config/utils";

export const login = value => {
    let error = {};

    if (!value.email) { error.email = 'Email is required' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }
    if (!value.password) { error.password = 'Password is required' }
    return error;
}

export const changePassword = value => {
    let error = {};

    if (!value.password) { error.password = 'Enter current password' }
    if (!value.newPassword) { error.newPassword = 'Enter new password' }
    else if (value.newPassword === value.password) { error.newPassword = 'Current password and new password can not be same' }
    else if (value.newPassword.length < 6) { error.newPassword = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Re enter confirm password' }
    if (value.repeatNewPassword !== value.newPassword) { error.repeatNewPassword = 'New password and confirm password must be same' }

    return error;
}


export const resetPassword = value => {
    let error = {};

    if (!value.password) { error.password = 'Enter new Password' }
    else if (value.password.length < 6) { error.password = "New password must contain at least 6 characters" }
    if (!value.repeatNewPassword) { error.repeatNewPassword = 'Renter confirm Password' }
    if (value.repeatNewPassword !== value.password) { error.repeatNewPassword = 'New password and confirm password must be same' }

    return error;
}

export const profile = value => {
    let error = {};

    if (!value.fullname || !value.fullname.trim()) {
        error.fullname = 'Please enter full name'
    }
    if (!value.username || !value.username.trim()) {
        error.username = 'Please enter user name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Contact Number."
    }
    else if (removeMobileFomrmatter(value.phoneNumber).length !== 13) {
        error.phoneNumber = "Should be 10 digits."
    }

    return error;
}
export const subAdminValidation = value => {
    let error = {};
    if (!value.fullname || !value.fullname.trim()) {
        error.fullname = 'Please enter full name'
    }
    if (!value.username || !value.username.trim()) {
        error.username = 'Please enter user name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Phone Number."
    }
    else if (removeMobileFomrmatter(value.phoneNumber).length !== 13) {
        error.phoneNumber = "Should be 10 digits."
    }

    if (!value.email) { error.email = 'Email is required' }
    else if (!/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }

    return error;
}

export const newRegister = value => {
    let error = {};
    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Phone Number."
    }
    if (!value.pincode || !value.pincode.trim()) {
        error.pincode = "Please enter pincode."
    }
    return error;
}

export const addNewAddress = value => {
    let error = {};
    if (!value.firstName || !value.firstName.trim()) {
        error.firstName = 'Please enter first name'
    }
    if (!value.lastName || !value.lastName.trim()) {
        error.lastName = 'Please enter last name'
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Phone Number."
    }
    if (!value.pincode || !value.pincode.trim()) {
        error.pincode = "Please enter pincode."
    }
    if (!value.street || !value.street.trim()) {
        error.street = 'Please enter wing/floor/flat/house no'
    }
    if (!value.apartment || !value.apartment.trim()) {
        error.apartment = "Please enter apartment name."
    }
    if (!value.streetAddress2 || !value.streetAddress2.trim()) {
        error.streetAddress2 = "Please enter locality"
    }
    if (!value.city || !value.city.trim()) {
        error.city = "Please enter city"
    }
    return error;
}

export const feedbackValidation = value => {
    let error = {};

    if (!value.status) {
        error.status = 'Please select status'
    }
    // if (!value.answer || !value.answer.trim()) { error.answer = 'Please enter your comment' }

    return error;
}

export const categoryForm = value => {
    let error = {};
    if (!value.type || !value.type.trim()) { error.type = 'Please enter product type' }
    if (!value.category || !value.category.trim()) { error.category = 'Please enter product category' }
    return error;
}

export const addReferralForm = value => {
    let error = {};
    if (!value.expDate || !value.expDate.trim()) { error.expDate = 'Please enter product type' }
    if (!value.minOrder || !value.minOrder.trim()) { error.minOrder = 'Please enter product category' }
    if (!value.noOfMonth || !value.noOfMonth.trim()) { error.noOfMonth = 'Please enter product category' }
    if (!value.productType || !value.productType.trim()) { error.productType = 'Please enter product type' }
    if (!value.productCategory || !value.productCategory.trim()) { error.productCategory = 'Please enter product category' }
    return error;
}
export const serviceAreaValidation = value => {
    let error = {};
    if (!value.state) { error.state = 'Please select state.' }
    if (!value.city) { error.city = 'Please select city' }
    if (!value.pincode || !value.pincode.trim()) { error.pincode = 'Please enter pincode' }
    else if (value.pincode.length < 6) {
        error.pincode = "pincode should be only 6 digits."
    }
    else if (value.pincode.length > 6) {
        error.pincode = "pincode should be only 6 digits."
    }
    return error;
}
export const promoCodeValidation = value => {
    let error = {};
    if (!value.codeNumber || !value.codeNumber.trim()) { error.codeNumber = 'Please promo code number.' }
    if (!value.codeTitle || !value.codeTitle.trim()) { error.codeTitle = 'Please enter promo code title.' }
    if (!value.description || !value.description.trim()) { error.description = 'Please enter promo code description.' }
    if (!value.discountAmount) { error.discountAmount = 'Please enter discount.' }
    if (!value.productCategory?.length) { error.productCategory = 'Please select product category.' }
    if (!value.productIds?.length) { error.productIds = 'Please select products.' }
    if (!value.productType?.length) { error.productType = 'Please select product type.' }
    if (!value.perUserUsage) { error.perUserUsage = 'Please enter usage user.' }
    // if (!value.inactiveReason || !value.inactiveReason.trim()) { error.inactiveReason = 'Please enter in-active reason.' }

    return error;
}

export const AssignProductValidation = value => {
    let error = {};
    if (!value.quantity) { error.quantity = 'Please enter quantity.' }
    if (!value.categoryId) { error.categoryId = 'Please select product category.' }
    if (!value.productId) { error.productId = 'Please select product.' }
    if (!value.productType) { error.productType = 'Please select product type.' }
    if (!value.option) {
        error.option = 'Option is required';
    }
    if (value.option && value.option.value !== 'return' && !value.reason) {
        error.reason = 'Reason is required';
    }
    // if (!value.skuId && value.categoryId !=2) { error.skuId = 'Please select SKU.' }

    return error;
}

export const driverModuleValidation = value => {
    let error = {};
    if (!value.firstName || !value.firstName.trim()) { error.firstName = 'Please enter First Name.' }
    else if (value.firstName.length < 2) {
        error.firstName = "First name must contain at least 2 characters."
    }
    else if (value.firstName.length > 15) {
        error.firstName = "First name should not be accept more than 15 characters."
    }

    if (!value.lastName || !value.lastName.trim()) { error.lastName = 'Please enter Last Name.' }
    else if (value.lastName.length < 2) {
        error.lastName = "Last name must contain at least 2 characters."
    }
    else if (value.lastName.length > 15) {
        error.lastName = "Last name  should not be accept more than 15 characters."
    }

    // if (!value.email) { error.email = 'Email is required' }
    if (value.email && !/^[^\W_](?:[\w.-]*[^\W_])?@(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.|(?:[\w-]+\.)+)(?:[a-zA-Z]{2,3}|[0-9]{1,3})\]?$/i.test(value.email)) {
        error.email = "Please enter valid email address."
    }
    if (!value.phoneNumber || !value.phoneNumber.trim()) {
        error.phoneNumber = "Please enter Contact Number."
    }
    else if (removeMobileFomrmatter(value.phoneNumber).length !== 13) {
        error.phoneNumber = "Should be 10 digits."
    }

    if (!value.address || !value.address.trim()) { error.address = 'Please enter address.' }
    if (!value.city) { error.city = 'Please select city.' }
    if (!value.state) { error.state = 'Please select state.' }
    // else if(! /^[A-Za-z ]+$/.test(value.state)) {
    //     error.state="state contains invalid characters."
    // }
    // if (!value.country || !value.country.trim()) { error.country = 'Please enter country.'}
    // else if(! /^[A-Za-z ]+$/.test(value.country)) {
    //     error.country="country contains invalid characters."
    // }
    if (!value.zipcode || !value.zipcode.trim()) { error.zipcode = 'Please enter zipcode.' }
    // if (!value.selectedVehicle) { error.selectedVehicle = 'Please select Vehicle.' }
    // if (!value.pincodes) { error.pincodes = 'Please select serviceable Pincodes.' }
    if (!value.licenseNumber) { error.licenseNumber = 'Please enter license Number.' }
    else if (value.licenseNumber.length > 18) {
        error.licenseNumber = "license no. should not be more than 18 characters."
    }
    if (!value.aadhareNumber) { error.aadhareNumber = 'Please enter aadhaar Number.' }
    else if (value.aadhareNumber.length > 12) {
        error.aadhareNumber = "Aadhaar no. should not be more than 12 digits."
    }
    if (!value.verificationStatus) { error.verificationStatus = 'Please select verification status.' }

    return error;
}
export const vehicleModuleValidation = value => {
    let error = {};
    if (!value.vehicleNumber || !value.vehicleNumber.trim()) { error.vehicleNumber = 'Please enter vehicle number.' }
    if (!value.vehicleName || !value.vehicleName.trim()) { error.vehicleName = 'Please enter vehicle name.' }
    // if (!value.quantity) { error.quantity = 'Please enter total quantity.' }
    return error;
}
export const FAQValidation = value => {
    let error = {};
    const FAQArrayErrors = []

    if (!value.categoryId) { error.categoryId = 'Please select category.' }
    if (value.addQuestions && value.addQuestions.length) {
        value.addQuestions.forEach((faq, faqIndex) => {
            const faqErrors = {}
            if (!faq || !faq.question || !faq.question.trim()) {
                faqErrors.question = 'please enter question'
                FAQArrayErrors[faqIndex] = faqErrors
            }
            if (!faq || !faq.answer || !faq.question.trim()) {
                faqErrors.answer = 'please enter answer'
                FAQArrayErrors[faqIndex] = faqErrors
            }
            return FAQArrayErrors
        })
    }
    return { ...error, addQuestions: FAQArrayErrors }
}
export const EditFAQValidation = value => {
    let error = {};
    const FAQArrayErrors = []

    if (!value.categoryId) { error.categoryId = 'Please select category.' }
    if (value.editQuestions && value.editQuestions.length) {
        value.editQuestions.forEach((faq, faqIndex) => {
            const faqErrors = {}
            if (!faq || !faq.question || !faq.question.trim()) {
                faqErrors.question = 'please enter question'
                FAQArrayErrors[faqIndex] = faqErrors
            }
            if (!faq || !faq.answer || !faq.answer.trim()) {
                faqErrors.answer = 'please enter answer'
                FAQArrayErrors[faqIndex] = faqErrors
            }
            return FAQArrayErrors
        })
    }
    return { ...error, editQuestions: FAQArrayErrors }
}

export const notificationForm = value => {
    let error = {};
    if (!value.userType) { error.userType = 'Please select user type' }
    if (!value.specificUser || !value.specificUser.length) { error.specificUser = 'Please select users' }
    if (!value.linkedTab) { error.linkedTab = 'Please select linked tab' }
    if (!value.message) { error.message = 'Please enter message' }
    if (!value.repeat) { error.repeat = 'Please select repeat frequency' }
    if (!value.date) { error.date = 'Please select date' }
    return error;
}


export const AddProduct = values => {
    let error = {};

    if (!values.productName || !values.productName.trim()) { error.productName = 'Enter product name' }
    if (!values.productType) { error.productType = 'Select product type' }
    else if (values.productType === 'false') { error.productType = 'Select product type' }
    if (!values.productCategory) { error.productCategory = 'Select product category' }
    if (!values.metakeyword) { error.metakeyword = 'Enter Meta Keyword' }
    if (!values.metadescription) { error.metadescription = 'Enter Meta Description' }
    if (!values.metatag) { error.metatag = 'Enter Meta Tags' }
    else if (values.productCategory === 'false') { error.productCategory = 'Select product category' }
    if (!values.productSummary || !values.productSummary) { error.productSummary = 'Enter product description' }
    else if(values?.productSummary?.length === 0){error.productSummary = 'Enter product description'}
    if (!values.productFragrance || !values.productFragrance.trim()) { error.productFragrance = 'Enter product fragrance' }
    if (!values.hsnCode || !values.hsnCode.trim()) { error.hsnCode = 'Enter HSN code' }
    if (!values.productMainImage) { error.productMainImage = 'Please select image' }
    if (!values.perLiterPrice || !values.perLiterPrice.trim()) { error.perLiterPrice = 'Enter per litre price' }
    if (!values.priceType || !values.priceType.trim()) { error.priceType = 'Select price type' }
    
    else if (Object.entries(values.productMainImage).length === 0) {
        error.productMainImage = 'Please choose one image'
    }
    if (values.sku && values.sku.length) {
        const productesArrayErrors = []
        values.sku.forEach((product, productIndex) => {

            const productErrors = {}
            if (!product || !product.skuName || !product.skuName.trim()) {
                productErrors.skuName = 'Enter sku name'
                productesArrayErrors[productIndex] = productErrors
            }
            if (!product || !product.productPrice || !product.productPrice.trim()) {
                productErrors.productPrice = 'Enter product price'
                productesArrayErrors[productIndex] = productErrors
            }
            if (!product || !product.salePrice || !product.salePrice.trim()) {
                productErrors.salePrice = 'Enter sale price'
                productesArrayErrors[productIndex] = productErrors
            }
            else if (product && Number(product?.productPrice) < Number(product?.salePrice)) {
                productErrors.salePrice = 'Sale price can not be greater than product price'
                productesArrayErrors[productIndex] = productErrors
            }
            // if (!product || !product.discount) {
            //     productErrors.discount = 'Enter discount'
            //     productesArrayErrors[productIndex] = productErrors
            // }
            // if (!product || !product.quantity) {
            //     productErrors.quantity = 'Enter quantity'
            //     productesArrayErrors[productIndex] = productErrors
            // }

            return productesArrayErrors
        })
        return { ...error, sku: productesArrayErrors };
    }

    return error;
}

export const EditProduct = values => {
    let error = {};

    if (!values.productName || !values.productName.trim()) { error.productName = 'Enter product name' }
    if (!values.productType) { error.productType = 'Select product type' }
    else if (values.productType === 'false') { error.productType = 'Select product type' }
    if (!values.productCategory) { error.productCategory = 'Select product category' }
    else if (values.productCategory === 'false') { error.productCategory = 'Select product category' }
    if (!values.productSummary || !values.productSummary) { error.productSummary = 'Enter product description' }
    else if(values?.productSummary?.length === 0){error.productSummary = 'Enter product description'}
    if (!values.productFragrance || !values.productFragrance.trim()) { error.productFragrance = 'Enter product fragrance' }
    if (!values.hsnCode || !values.hsnCode.trim()) { error.hsnCode = 'Enter HSN code' }
    if (!values.skuName || !values.skuName.trim()) { error.skuName = 'Enter sku name' }
    // if (!values.discount || !values.discount.trim()) { error.discount = 'Enter discount' }
    if (!values.productPrice) { error.productPrice = 'Enter product price' }
    if (!values.metakeyword) { error.metakeyword = 'Enter Meta Keyword' }
    if (!values.metadescription) { error.metadescription = 'Enter Meta Description' }
    if (!values.metatag) { error.metatag = 'Enter Meta Tags' }
    if (!values.perLiterPrice || !values.perLiterPrice.trim()) { error.perLiterPrice = 'Enter per litre price' }
    if (!values.salePrice) { error.salePrice = 'Enter sale price' }
    if (values?.productPrice && values?.salePrice) {
        if (Number(values?.salePrice) > Number(values?.productPrice)) {
            error.salePrice = 'Sale price can not be greater than product price'
        }
    }
    // if (!values.inititalQuantity) { error.inititalQuantity = 'Enter quantity' }

    return error;
}
export const releaseValidation = value => {
    let error = {};
    if (!value.vehicleReleaseReason || !value.vehicleReleaseReason.trim()) { error.vehicleReleaseReason = 'Please enter release reason.' }

    return error;
}

export const AddCms = values => {
    let error = {};

    if (!values.cmsTitle || !values.cmsTitle.trim()) { error.cmsTitle = 'Enter page title' }
    if (!values.cmsKey || !values.cmsKey.trim()) { error.cmsKey = 'Enter CMS Key' }
    if (!values.metatitle || !values.metatitle.trim()) { error.metatitle = 'Enter Meta Title' }
    // if (!values.metatag || !values.metatag.trim() ) { error.metatag = 'Enter meta tags' }
    if (!values.metakeyword || !values.metakeyword.trim() ) { error.metakeyword = 'Enter Meta Keyword' }
    if (!values.metadescription || !values.metadescription.trim()) { error.metadescription = 'Enter meta description' }
    if (!values.Content || !values.Content.trim()) { error.Content = 'Enter content' }
    return error;
}

export const AddProductType = values => {
    let error = {};

    if (!values.type || !values.type.trim()) { error.type = 'Enter Product Type' }
    if (!values.metatitle || !values.metatitle.trim()) { error.metatitle = 'Enter Meta Title' }
    if (!values.metatag || !values.metatag.trim()) { error.metatag = 'Enter Meta Tags' }
    if (!values.metadescription || !values.metadescription.trim() ) { error.metadescription = 'Enter Meta Description' }
    if (!values.metakeyword || !values.metakeyword.trim() ) { error.metakeyword = 'Enter Meta Keyword' }
    return error;
}