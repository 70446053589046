import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    productRatingList: [],
}

const productRating = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_PRODUCT_RATING_LIST_S:
            return {
                ...state,
                productRatingList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_RATING_LIST_F:
                return {
                    ...state,
                    productRatingList: [],
                    error: action.payload.data.message
                }
        default:
            return state
    }

}

export default productRating