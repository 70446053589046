import * as ACTION_TYPES from '../constants/types';

const initialState = {
    extra: {},
    cartList: [],
    message: null,
    error: null,
    referralCode: [],
    referralProduct: [],
    referralDiscount: '',
    isAppliedReferralDiscount: false
}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_CART_DEAILS_SUCCESS:
            return {
                ...state,
                cartList: action.payload.data.list,
                extra: action.payload.data.extra,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CART_DEAILS_ERROR:
            return {
                ...state,
                cartList: [],
                extra: {},
            }
        case ACTION_TYPES.CHECK_REFERRAL_CODE_SUCCESS:
            return {
                ...state,
                referralCode: action.payload.data ? action.payload.data : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CHECK_REFERRAL_CODE_ERROR:
            return {
                ...state,
                referralCode: [],
            }
        case ACTION_TYPES.CHECK_AND_GET_REFERRAL_SUCCESS:
            return {
                ...state,
                referralProduct: action.payload.data ? action.payload.data : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CHECK_AND_GET_REFERRAL_ERROR:
            return {
                ...state,
                referralProduct: [],
            }
        case ACTION_TYPES.ADD_AND_REMOVE_REFERRAL_CODE:
            return {
                ...state,
                referralDiscount: action.payload.referralDiscount,
                isAppliedReferralDiscount: action.payload.isAppliedReferralDiscount
            }
        case ACTION_TYPES.REMOVE_REFERRAL_CODE:
            return {
                ...state,
                referralCode: [],
                message: "Referral code removed successfully",
                error: null
            }
        default:
            return state;
    }
}
export default cartReducer