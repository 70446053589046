import 'firebase/messaging'
import firebase from 'firebase/app'
import { toast } from 'react-toastify';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyAgLcx3iDV2ceyeeKXt8W88uETxAPQHR9w',
    authDomain: "clefill.firebaseapp.com",
    projectId: "clefill",
    storageBucket: "clefill.appspot.com",
    messagingSenderId: "514002523490",
    appId: "1:514002523490:web:30580d5f3807a9bda0883b",
    measurementId: "G-BZCT0P442C"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

let messaging = null;

if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging()
}

export const getToken = (setTokenFound) => {
    return messaging.getToken({ vapidKey: process.env.REACT_APP_FIREBASE_KEY }).then((currentToken) => {
        if (currentToken) {
            // console.log('currentToken: ', currentToken);
            // console.log('current token for client: ', currentToken);
            localStorage.setItem("FCMToken", JSON.stringify(currentToken))
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        window.Notification.requestPermission()
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>

    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            toast.info(`${payload?.notification?.title} ${payload?.notification?.body}`,
                {
                    position: "bottom-right",
                    autoClose: 6000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    theme: 'light',
                    progress: undefined,
                    className: 'toast-text font-small',
                    style: { zIndex: 999999999999999999 }
                });
            // resolve(payload);
        });
    });
