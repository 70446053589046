import {
    CUSTOMER_SEARCH,
    ORDER_SEARCH,
    PRODUCT_SEARCH,
    PRODUCT_CATAGOTY_STATE,
    PRODUCT_TYPE_STATE,
    PRODUCT_STATE,
    ORDER_STATE,
    PINCODE_STATE
} from "src/constants/types"

const initialState = {
    customerSearch: '',
    productSearch: '',
    orderSearch: ''
}

const searchState = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_SEARCH:
            return {
                ...state,
                customerSearch: action.payload,
            }
        case PRODUCT_SEARCH:
            return {
                ...state,
                productSearch: action.payload,
            }
        case ORDER_SEARCH:
            return {
                ...state,
                orderSearch: action.payload,
            }
        case PRODUCT_CATAGOTY_STATE:
            return {
                ...state,
                productCatagorySelectState: action.payload,
            }
        case PRODUCT_TYPE_STATE:
            return {
                ...state,
                prodyctTypeSelectState: action.payload,
            }
        case PRODUCT_STATE:
            return {
                ...state,
                productSelectState: action.payload,
            }
        case ORDER_STATE:
            return {
                ...state,
                orderSelectState: action.payload,
            }
        case PINCODE_STATE:
            return {
                ...state,
                pincodeSelectState: action.payload,
            }
        default:
            return state
    }
}

export default searchState