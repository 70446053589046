import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    feedbackList:[],
    feedbackInfo: {},
    isUpdated:false
}

const feedbackReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_FEEDBACK_LIST_S:
            return {
                ...state,
                feedbackList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null,
                isUpdated:false

            }
        case ACTION_TYPES.GET_FEEDBACK_LIST_F:
            return {
                ...state,
                feedbackList:[],
            }
        case ACTION_TYPES.GET_FEEDBACK_INFO_S:
            return {
                ...state,
                feedbackInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FEEDBACK_INFO_F:
        case ACTION_TYPES.UPDATE_STATUS_FEEDBACK_F:
            return {
                ...state,
                feedbackInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isUpdated:false
            }
            case ACTION_TYPES.UPDATE_STATUS_FEEDBACK_S:
            return {
                ...state,
                feedbackInfo: action.payload.data,
                message: action.payload.message,
                error: null,
                isUpdated:true
            }

        default:
            return state
    }

}

export default feedbackReducer