import * as ACTION_TYPES from '../constants/types';

const initialState = {
    productTypeList: [],
    productTypeInfo: {},
    productTypeLoop: [],
    error: null
}

const productTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_PRODUCT_TYPE_LIST_SUCCESS:
            return {
                ...state,
                productTypeList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_TYPE_LIST_ERROR:
            return {
                ...state,
                productTypeList: [],
            }
        case ACTION_TYPES.GET_PRODUCT_TYPE_INFO_SUCCESS:
            return {
                ...state,
                productTypeInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_TYPE_INFO_ERROR:
            return {
                ...state,
                productTypeInfo: {},
            }
        case ACTION_TYPES.GET_PRODUCT_TYPE_LIST_DD_SUCCESS:
            return {
                ...state,
                productTypeLoop: action.payload.data.list ? action.payload.data.list : [],
                message: action.payload.message,
                error: null
            }
        default:
            return state;
    }
}
export default productTypeReducer