import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    driverList: [],
    driverInfo: {},

    // get driver list who has vehicle for assign the order to them in order module
    driverWithVehicleList: [],
    availbleVehicles: [],
    stateList: [],
    cityList: [],
    citiesList: []
}

const driverReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_DRIVER_LIST_S:
            return {
                ...state,
                driverList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_AVAIABLE_VEHICLES_F:
            return {
                ...state,
                availbleVehicles: [],
            }
        case ACTION_TYPES.GET_DRIVER_LIST_W_VEHICLE_S:
            return {
                ...state,
                driverWithVehicleList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DRIVER_LIST_W_VEHICLE_F:
            return {
                ...state,
                driverWithVehicleList: [],
            }
        case ACTION_TYPES.GET_STATE_LIST_S:
            return {
                ...state,
                stateList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_STATE_LIST_F:
            return {
                ...state,
                stateList: [],
            }
        case ACTION_TYPES.GET_CITY_LIST_S:
            return {
                ...state,
                cityList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CITY_LIST_F:
            return {
                ...state,
                cityList: [],
            }
        case ACTION_TYPES.GET_CITIES_LIST_S:
            return {
                ...state,
                citiesList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CITIES_LIST_F:
            return {
                ...state,
                citiesList: [],
            }

        case ACTION_TYPES.GET_AVAIABLE_VEHICLES_S:
            return {
                ...state,
                availbleVehicles: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DRIVER_LIST_F:
            return {
                ...state,
                driverList: [],
            }
        case ACTION_TYPES.ADD_DRIVER_S:
        case ACTION_TYPES.GET_DRIVER_INFO_S:
        case ACTION_TYPES.EDIT_DRIVER_S:
            return {
                ...state,
                driverInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DRIVER_INFO_F:
        case ACTION_TYPES.ADD_DRIVER_F:
        case ACTION_TYPES.EDIT_DRIVER_F:
        case ACTION_TYPES.DEL_DRIVER_S:
        case ACTION_TYPES.DEL_DRIVER_F:
            return {
                ...state,
                driverInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }

}

export default driverReducer