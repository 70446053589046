import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    areaList:[],
    apartmentList:[],
    areaInfo: {},
    activeAreaList: [],
}

const areaReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_AREA_LIST_S:
            return {
                ...state,
                areaList: action.payload.data.areaList ? action.payload.data?.areaList : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_AREA_LIST_F:
            return {
                ...state,
                areaList:[],
            }
        case ACTION_TYPES.GET_ACTIVE_AREA_LIST_S:
            return {
                ...state,
                activeAreaList: action.payload.data.areaList ? action.payload.data?.areaList : [],
                message: action.payload.message,
                error: null
            }
            case ACTION_TYPES.GET_ACTIVE_AREA_LIST_F:
                return {
                    ...state,
                    activeAreaList:[],
                }
        case ACTION_TYPES.AREA_PAGE_CHANGE:
            return {
                ...state,
                page: action.payload
            }
        case ACTION_TYPES.AREA_LIMIT_CHANGE:
            return {
                ...state,
                limit: action.payload
            }
        case ACTION_TYPES.ADD_AREA_S:
        case ACTION_TYPES.GET_AREA_INFO_S:
        case ACTION_TYPES.EDIT_AREA_S:
            return {
                ...state,
                areaInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_AREA_INFO_F:
        case ACTION_TYPES.ADD_AREA_F:
        case ACTION_TYPES.EDIT_AREA_F:
        case ACTION_TYPES.DEL_AREA_S:
        case ACTION_TYPES.DEL_AREA_F:
            return {
                ...state,
                areaInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }

}

export default areaReducer