import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    customerList: [],
    customerOrderList: { normal: [], return: [] },
    customerInfo: {},
    customerAddress: [],
    customerListWihoutOrder: [],
}

const customerReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_CUSTOMER_LIST_S:
            return {
                ...state,
                customerList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CUSTOMER_LIST_F:
            return {
                ...state,
                customerList: [],
            }
        case ACTION_TYPES.GET_CUSTOMER_INFO_S:
            return {
                ...state,
                customerInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CUSTOMER_INFO_F:
            return {
                ...state,
                customerInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.GET_CUSTOMER_ORDER_LIST_S:
            return {
                ...state,
                customerOrderList: action.payload.data,
            }
        case ACTION_TYPES.GET_CUSTOMER_ORDER_LIST_F:
            return {
                ...state,
                customerOrderList: { normal: [], return: [] },
            }
        case ACTION_TYPES.FETCH_CUSTOMER_ADDRESS_SUCCESS:
            return {
                ...state,
                customerAddress: action.payload.data
            }
        case ACTION_TYPES.FETCH_CUSTOMER_ADDRESS_ERROR:
            return {
                ...state,
                customerAddress: action.payload.data
            }
        case ACTION_TYPES.GET_CUSTOMER_WITHOUT_ORDER_LIST_S:
            return {
                ...state,
                customerListWihoutOrder: action.payload.data.list
            }
        case ACTION_TYPES.GET_CUSTOMER_WITHOUT_ORDER_LIST_F:
            return {
                ...state,
                customerListWihoutOrder: action.payload.data.list
            }
        default:
            return state
    }

}

export default customerReducer