export const { REACT_APP_IMAGE_BASE: BASE_URL_IMAGE } = process.env

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname//To concate the path for the public folder

export const DriverProfile = pathname => BASE_URL_IMAGE + 'driver/' + pathname
export const VehicleImage = pathname => BASE_URL_IMAGE + 'vehicle/' + pathname
export const AadharImage = pathname => BASE_URL_IMAGE + 'aadhar/' + pathname
export const LicenseImage = pathname => BASE_URL_IMAGE + 'license/' + pathname
export const subAdminProfile = pathname => BASE_URL_IMAGE + 'admin/' + pathname
export const productImagePath = pathname => BASE_URL_IMAGE + 'products/' + pathname
export const productReviewImagePath = pathname => BASE_URL_IMAGE + 'productreview/' + pathname


export function createDeviceId(length = 5) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const findCityFromGoogleMap = (data = [], type = 'locality') => {
    if (data) {
        const localityObject = data?.filter((obj) => {
            return obj.types.includes(type);
        })[0];
        const city = localityObject?.long_name;
        return city
    }
    else {
        return ''
    }
}

export const generateReferralCode = (customerId) => {
    // const paddedCustomerId = customerId.toString().padStart(5, '0'); 
    // const randomString = Math.random().toString(36).substring(2, 4);
    const referralCode = `CLEFILL${customerId}`.toUpperCase();

    return referralCode;
}