import * as ACTION_TYPES from '../constants/types';

const initialState = {

    // Dashboard
    dashboardInfo: {},
    dashboardOrderList: [],
    dashboardDriverList: [],
    dashboardDeliveredOrderList: [],

    // Visitors 
    visitors: [],

    // Order Reports
    orderPlaced: [],
    returnDelivery: [],
    codOrder: [],
    completedOrder: [],


    // driver Reports
    driverList: [],

    error: null
}

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_DASHBOARD_ORDER_LIST_S:
            return {
                ...state,
                dashboardOrderList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DASHBOARD_ORDER_LIST_F:
            return {
                ...state,
                dashboardOrderList: [],
            }
        case ACTION_TYPES.GET_DASHBOARD_DRIVER_LIST_S:
            return {
                ...state,
                dashboardDriverList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DASHBOARD_DRIVER_LIST_F:
            return {
                ...state,
                dashboardDriverList: [],
            }
        case ACTION_TYPES.GET_DASHBOARD_DELIVERED_ORDER_LIST_S:
            return {
                ...state,
                dashboardDeliveredOrderList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_DASHBOARD_DELIVERED_ORDER_LIST_F:
            return {
                ...state,
                dashboardDeliveredOrderList: [],
            }

        // WORK
        case ACTION_TYPES.GET_CMS_LIST_SUCCESS:
            return {
                ...state,
                orderPlaced: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CMS_LIST_ERROR:
            return {
                ...state,
                orderPlaced: [],
            }
        case ACTION_TYPES.GET_CMS_INFO_SUCCESS:
            return {
                ...state,
                cmsInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        default:
            return state;
    }
}
export default reportsReducer