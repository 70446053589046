import React from 'react'
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom'
import { reduxForm, Field } from 'redux-form'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, /*CInput,*/ CInputGroup, CInputGroupPrepend, CInputGroupText, CRow } from '@coreui/react'
import { renderInput } from 'src/FormField/FormField';
import { resetPassword as validate } from '../../../validate/validate'
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { toAbsoluteUrl } from 'src/utils';
import { resetPassword } from 'src/actions/login';

const TITLE = "Clefill - Reset Password";

const ResetPassword = ({ handleSubmit, match }) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [isDisabled, setDisabled] = useState(false);

    const onFormSubmit = data => {
        setDisabled(true);
        dispatch(resetPassword({ ...data, token: match.params.resetCode }))
            .then(res => {
                if (res.status === 200) {
                    toastr.success('Success', res.message)
                    history.push('/')
                } else {
                    setDisabled(false)
                    toastr.error('Error', res.message)
                }
            })
            .catch(err => {
                setDisabled(false)
                if (err.status === 400) {
                    toastr.error('Error', err.message)
                }
                else {
                    toastr.error('Error', "Please try again.")
                }
            })
    }

    return (
        <>
            <Helmet>
                <title>{TITLE}</title>
            </Helmet>
            <div className="c-default-layout flex-row align-items-center ">
                <CContainer>
                    <CRow className="justify-content-center pt-9 pb-9">
                        <CCol md="6">
                            <div className="center-block">
                                <div className="center-logo">
                                    <img src={toAbsoluteUrl('/avatars/logo.svg')} alt="Clefill" />
                                </div>
                            </div>
                            <CCardGroup>
                                <CCard className="p-0 pt-6">
                                    <CCardBody>
                                        <CForm onSubmit={handleSubmit(onFormSubmit)}>
                                            <h2 className="text-center mb-3">Reset Password</h2>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <i className="fal fa-unlock-alt"></i>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <Field required={true}component={renderInput} name="password" type="password" placeholder="New Password" autoComplete="new-password" />
                                            </CInputGroup>
                                            <CInputGroup className="mb-4">
                                                <CInputGroupPrepend>
                                                    <CInputGroupText>
                                                        <i className="fal fa-unlock-alt"></i>
                                                    </CInputGroupText>
                                                </CInputGroupPrepend>
                                                <Field required={true}component={renderInput} name="repeatNewPassword" type="password" placeholder="Confirm New Password" autoComplete="repeat-new-password" />
                                            </CInputGroup>
                                            <div className="d-flex flex-wrap justify-content-center">
                                                <button color="success" type="submit" disabled={isDisabled} className="m-1 btn btn-success">Save</button>
                                            </div>
                                        </CForm>
                                    </CCardBody>
                                </CCard>
                            </CCardGroup>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
        </>
    )
}

export default reduxForm({
    form: 'ResetPassword',
    validate
})(ResetPassword);
