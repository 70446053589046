import * as ACTION_TYPES from '../constants/types';

const initialState = {
    cmsList: { list: [] },
    cmsInfo: [{}],
    error: null
}

const cmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GET_CMS_LIST_SUCCESS:
            return {
                ...state,
                cmsList: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_CMS_LIST_ERROR:
            return {
                ...state,
                cmsList: { list: [] },
            }
        case ACTION_TYPES.GET_CMS_INFO_SUCCESS:
            return {
                ...state,
                cmsInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        default:
            return state;
    }
}
export default cmsReducer