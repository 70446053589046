import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    promoCodeList: [],
    promoCodeInfo: {},
    allProductTypes: [],
    allProducts: [],
    allCategoryTypes: [],
    checkedPromocodeList: []
}

const promoCodeReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_PROMO_CODE_LIST_S:
            return {
                ...state,
                promoCodeList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CHK_PROMO_CODE_LIST_S:
            console.log(action.payload.data)
            return {
                ...state,
                checkedPromocodeList: action.payload.data ? action.payload.data : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.CHK_PROMO_CODE_LIST_F:
                return {
                    ...state,
                    checkedPromocodeList: [],
                    error: action.payload.data.message
                }
        case ACTION_TYPES.GET_ALL_CATEGORY_TYPES_F:
            return {
                ...state,
                allCategoryTypes: [],
            }
        case ACTION_TYPES.GET_ALL_CATEGORY_TYPES_S:
            return {
                ...state,
                allCategoryTypes: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ALL_PRODUCT_TYPES_F:
            return {
                ...state,
                allProductTypes: [],
            }
        case ACTION_TYPES.GET_ALL_PRODUCT_TYPES_S:
            return {
                ...state,
                allProductTypes: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ALL_PRODUCTS_F:
            return {
                ...state,
                allProducts: [],
            }
        case ACTION_TYPES.GET_ALL_PRODUCTS_S:
            return {
                ...state,
                allProducts: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PROMO_CODE_LIST_F:
            return {
                ...state,
                promoCodeList: [],
            }
        case ACTION_TYPES.ADD_PROMO_CODE_S:
        case ACTION_TYPES.GET_PROMO_CODE_INFO_S:
        case ACTION_TYPES.EDIT_PROMO_CODE_S:
            return {
                ...state,
                promoCodeInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PROMO_CODE_INFO_F:
        case ACTION_TYPES.ADD_PROMO_CODE_F:
        case ACTION_TYPES.EDIT_PROMO_CODE_F:
        case ACTION_TYPES.DEL_PROMO_CODE_S:
        case ACTION_TYPES.DEL_PROMO_CODE_F:
            return {
                ...state,
                promoCodeInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }

}

export default promoCodeReducer