import {
    API_GET_ORDER_LIST,
    API_GET_ORDER_INFO,
    API_GET_RETURN_ORDER_LIST,
    API_ASSIGN_DRIVER_TO_ORDER,
    API_APRROVE_REJECT_RETURN_ORDER,
    API_GET_ORDER_LOGS,
    API_SEND_PAYMENT_LINK,
    API_UPDATE_ORDER_STATUS,
    API_EXPORT_PDF,
    API_VISITORS_EXPORT_PDF,
    API_DOWNLOAD_INVOICE,
    API_DOWNLOAD_INVOICE_ZIP,
    API_SEND_CANCEL_ORDER,
    API_ADD_ADMIN_INSTRUCTION,
} from '../constants/api'
import {
    API,
    GET_ORDER_LIST_F,
    GET_ORDER_LIST_S,
    GET_ORDER_INFO_S,
    GET_ORDER_INFO_F,
    GET_RETURN_ORDER_LIST_S,
    GET_RETURN_ORDER_LIST_F,
} from "../constants/types";

//get Order list
export const getOrderList = (args) => ({
    type: API,
    payload: {
        url: API_GET_ORDER_LIST,
        method: 'POST',
        data: args,
        success: (data) => ({
            type: GET_ORDER_LIST_S,
            payload: data
        }),
        error: (data) => ({
            type: GET_ORDER_LIST_F,
            payload: data
        })
    }
})
//get order info
export const getOrderDetails = (id) => ({
    type: API,
    payload: {
        url: API_GET_ORDER_INFO + `?id=${id}`,
        method: 'GET',
        success: (data) => ({
            type: GET_ORDER_INFO_S,
            payload: data
        }),
        error: (data) => ({
            type: GET_ORDER_INFO_F,
            payload: data
        })
    }
})
//get Return Order list
export const getReturnOrderList = (data) => ({
    type: API,
    payload: {
        url: API_GET_RETURN_ORDER_LIST,
        data: data,
        method: 'POST',
        success: (data) => ({
            type: GET_RETURN_ORDER_LIST_S,
            payload: data
        }),
        error: (data) => ({
            type: GET_RETURN_ORDER_LIST_F,
            payload: data
        })
    }
})
//Assign driver to order
export const assignDriverToOrder = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_ASSIGN_DRIVER_TO_ORDER,
        method: 'PUT',
    }
})

//Assign driver to order
export const returnOrderApproveReject = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_APRROVE_REJECT_RETURN_ORDER,
        method: 'PUT',
    }
})

//get order log
export const getOrderLog = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_GET_ORDER_LOGS,
        method: 'POST',
    }
})

//send payment link
export const sendPaymentLink = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_SEND_PAYMENT_LINK,
        method: 'POST',
    }
})

//update status of order
export const toUpdateOrderStatus = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_UPDATE_ORDER_STATUS,
        method: 'PUT',
    }
})

export const addAdminInstruction = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_ADD_ADMIN_INSTRUCTION,
        method: 'POST',
    }
})

//On export PDF
export const exportPdfAction = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_EXPORT_PDF,
        method: 'POST',
    }
})

//On visitors export PDF
export const exportVisitorsPdfAction = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_VISITORS_EXPORT_PDF,
        method: 'POST',
    }
})

export const toGetInvoice = (data) => ({
    type: API,
    payload: {
        url: API_DOWNLOAD_INVOICE,
        method: 'POST',
        data: data,
    }
})

export const toGetZipInvoice = (data) => ({
    type: API,
    payload: {
        url: `${API_DOWNLOAD_INVOICE_ZIP}?startDate=${data.startDate}&endDate=${data.endDate}`,
        method: 'GET',
        data: data,
    }
})

export const cancelOrder = (data) => ({
    type: API,
    payload: {
        data: data,
        url: API_SEND_CANCEL_ORDER,
        method: 'POST',
    }
})