import React, { useRef } from "react";
import { CCol, CInput, CTextarea, CLabel, CFormGroup } from "@coreui/react";
import NumberFormat from "react-number-format";
import SelectMulti from "react-select";
import ReactQuill from "react-quill";
import EditorToolbar, {
  formats,
  modules,
} from "src/views/pages/cms-management/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import { Autocomplete } from "@react-google-maps/api";
import { findCityFromGoogleMap } from "src/utils";

export const renderInputField = ({
  input,
  label,
  type,
  isDisabled,
  required,
  meta: { touched, error, warning },
}) => (
  <CFormGroup row>
    <CCol md="3">
      <CLabel htmlFor="text-input">
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {label}
      </CLabel>
    </CCol>
    <CCol xs="12" md="6">
      <CInput
        {...input}
        type={type}
        placeholder={label}
        disabled={isDisabled}
      />
      <div className="text-danger">
        {touched && error && (
          <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
            {error}
          </h6>
        )}
      </div>
    </CCol>
  </CFormGroup>
);

export const renderInput = ({
  input,
  type,
  autoComplete,
  placeholder,
  step,
  disabled,
  name,
  meta: { touched, error },
}) => {
  return (
    <>
      <CInput
        {...input}
        type={type}
        step={step}
        name={name}
        min={type === "number" ? "1" : null}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
      {touched && error && (
        <h6 style={{ color: "red", width: "100%" }}>{error}</h6>
      )}
    </>
  );
};

export const RenderFileField = ({
  input,
  brandPic,
  className,
  type,
  meta: { touched, error },
}) => {
  let ref = useRef();
  return (
    <>
      <div className="d-flex">
        {brandPic.src && (
          <div className="image-item">
            <img src={brandPic.src} alt={brandPic.alt} height="90px" />
          </div>
        )}
        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className="upload-input"
        >
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              ref.current.click();
            }}
          >
            {" "}
            <i className="fal fa-upload"></i>{" "}
          </button>
        </div>
      </div>
      <input
        {...input}
        ref={ref}
        className={"d-none"}
        type={type}
        accept="image/jpeg, image/png, image/jpg, image/avif"
        value={undefined}
      />
      <div className="text-danger ml-1">
        {touched && error && (
          <h6
            className="mb-0 pl-2"
            style={{ color: "red", textAlign: "left", width: "100%" }}
          >
            {error}
          </h6>
        )}
      </div>
    </>
  );
};
export const RenderFileFieldMulti = ({
  input,
  brandPic, // Array of base64 image strings
  className,
  type,
  meta: { touched, error },
}) => {
  let ref = useRef();
  return (
    <>
      <div className="d-flex">
        {/* Loop through the brandPic array to show all previews */}
        {brandPic && brandPic.length > 0 && brandPic.map((pic, index) => (
          <div key={index} className="image-item mr-2">
            <img src={pic} alt={`Image Preview ${index + 1}`} height="90px" />
          </div>
        ))}

        <div
          onClick={(e) => {
            e.preventDefault();
          }}
          className="upload-input"
        >
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              ref.current.click();
            }}
          >
            <i className="fal fa-upload"></i>
          </button>
        </div>
      </div>

      {/* Input accepts multiple images */}
      <input
        {...input}
        ref={ref}
        className={"d-none"}
        type={type}
        accept="image/jpeg,image/png,image/jpg"
        value={undefined}
        multiple  // Allow multiple image uploads
      />

      <div className="text-danger ml-1">
        {touched && error && (
          <h6
            className="mb-0 pl-2"
            style={{ color: "red", textAlign: "left", width: "100%" }}
          >
            {error}
          </h6>
        )}
      </div>
    </>
  );
};


export const SelectField = ({
  input,
  selectMap,
  formClass,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <div className={`form-group ${formClass}`}>
      <div className="form-block">
        <select {...input} className="form-control" placeholder={placeholder}>
          {selectMap.map((value, index) => {
            return (
              <option key={index} value={value.option}>
                {value.label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </div>
  );
};

export const renderEditInputField = ({
  input,
  placeholder,
  disabled,
  type,
  labelName,
  isWithIcon,
  required,
  extraClassName,
  isHalf,
  isRow,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isHalf ? `6` : isRow ? "3" : `4`}>
      {!isWithIcon ? (
        <label>
          {" "}
          {required ? (
            <span className="text-danger font-weight-bolder">*</span>
          ) : (
            ""
          )}{" "}
          {labelName}
        </label>
      ) : (
        <label>
          {" "}
          {labelName}
          <i
            className="fal fa-info ml-3 mb-1"
            style={{ cursor: "pointer" }}
            data-for="main"
            data-tip
            data-iscapture="true"
          // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
          />
        </label>
      )}
      <CInput
        {...input}
        disabled={disabled}
        type={type}
        className={extraClassName}
        min={type === "number" ? "1" : null}
        pattern={pattern}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditInputFieldWithValue = ({
  input,
  placeholder,
  disabled,
  type,
  labelName,
  isWithIcon,
  required,
  extraClassName,
  isHalf,
  isValue,
  isRow,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isHalf ? `6` : isRow ? "3" : `4`}>
      {!isWithIcon ? (
        <label>
          {" "}
          {required ? (
            <span className="text-danger font-weight-bolder">*</span>
          ) : (
            ""
          )}{" "}
          {labelName}
        </label>
      ) : (
        <label>
          {" "}
          {labelName}
          <i
            className="fal fa-info ml-3 mb-1"
            style={{ cursor: "pointer" }}
            data-for="main"
            data-tip
            data-iscapture="true"
          // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
          />
        </label>
      )}
      <CInput
        {...input}
        disabled={disabled}
        type={type}
        className={extraClassName}
        min={type === "number" ? "1" : null}
        pattern={pattern}
        value={isValue}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};


export const renderEditInputFieldFull = ({
  input,
  placeholder,
  disabled,
  type,
  required,
  labelName,
  isWithIcon,
  isFull,
  pattern,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isFull ? `12` : `4`}>
      {!isWithIcon ? (
        <label>
          {" "}
          {required ? (
            <span className="text-danger font-weight-bolder">*</span>
          ) : (
            ""
          )}{" "}
          {labelName}
        </label>
      ) : (
        <label>
          {" "}
          {labelName}
          <i
            className="fal fa-info ml-3 mb-1"
            style={{ cursor: "pointer" }}
            data-for="main"
            data-tip
            data-iscapture="true"
          // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
          />
        </label>
      )}
      <CInput
        {...input}
        disabled={disabled}
        type={type}
        pattern={pattern}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderEditTextareaField = ({
  input,
  placeholder,
  disabled,
  labelName,
  required,
  rows,
  isFull,
  meta: { touched, error },
}) => {
  return (
    <CCol md="6" lg={isFull ? `12` : `4`}>
      <label>
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
      <CTextarea
        {...input}
        rows={rows}
        disabled={disabled}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const RenderQuill = ({
  disabled,
  labelName,
  required,
  setproductDesc,
  productDesc,
  height = "200px",
  isFull,
}) => {
  return (
    <CCol md="6" lg={isFull ? `12` : `4`}>
      <label>
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
      <div className={disabled ? "pointer-event-none" : ""}>
        <EditorToolbar />
        <ReactQuill
          style={{ maxHeight: height, overflowY: "scroll" }}
          theme="snow"
          placeholder={"Write something"}
          value={productDesc}
          onChange={(e) => {
            setproductDesc(e);
          }}
          modules={modules}
          formats={formats}
        />
      </div>
    </CCol>
  );
};

export const renderEditSelectField = ({
  input,
  disabled,
  placeholder,
  isHalf,
  required,
  value,
  selectMap,
  labelName,
  meta: { touched, error },
}) => {
  return (
    <CCol lg={isHalf ? "6" : "4"}>
      <label>
        {" "}
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
      <select
        {...input}
        value={value}
        disabled={disabled}
        className="form-control"
        placeholder={placeholder}
      >
        {selectMap.map((value, index) => {
          return (
            <option key={index} value={value.option}>
              {value.label}
            </option>
          );
        })}
      </select>
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderReactSelectMulti = ({
  input,
  isMulti,
  selectMap,
  className,
  isHalf,
  required,
  labelName,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <CCol lg={isHalf ? "6" : "4"}>
      <label>
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
      <SelectMulti
        {...input}
        options={selectMap.length > 0 ? selectMap : []}
        className={className}
        color={"#405a8b"}
        isMulti={isMulti}
        isSearchable={true}
        isDisabled={disabled}
        onBlur={() => input.onBlur()}
        onFocus={() => input.onFocus()}
        onChange={(value) => {
          input.onChange(value);
        }}
        noOptionsMessage={({ inputValue }) => {
          return !inputValue ? "All Selected" : "No results found";
        }}
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderReactSelectAll = ({
  input,
  isMulti,
  selectMap,
  className,
  isHalf,
  multi,
  required,
  labelName,
  disabled,
  meta: { touched, error },
}) => {
  const options =
    multi && selectMap.length
      ? [{ label: "Select All", value: "all" }, ...selectMap]
      : selectMap;

  return (
    <CCol lg={isHalf ? "6" : "4"}>
      <label>
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
      <SelectMulti
        {...input}
        options={options}
        className={className}
        color={"#405a8b"}
        isMulti={multi}
        isSearchable={true}
        isDisabled={disabled}
        onBlur={() => input.onBlur()}
        onFocus={() => input.onFocus()}
        onChange={(selected) => {
          selected.length && selected.find((option) => option.value === "all")
            ? input.onChange(options.slice(1))
            : input.onChange(selected);
        }}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? "All Selected" : "No results found"
        }
      />
      {touched && error && (
        <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
          {error}
        </h6>
      )}
    </CCol>
  );
};

export const renderNumber = ({
  input,
  type,
  styleField,
  className,
  placeholder,
  disabled,
  formatField,
  meta: { touched, error },
}) => {
  return (
    <>
      <NumberFormat
        {...input}
        format={formatField}
        style={styleField}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
      />
      {touched && error && (
        <h6 style={{ color: "red", width: "100%" }}>{error}</h6>
      )}
    </>
  );
};

export const renderNumberField1 = ({
  input,
  labelName,
  type,
  icon,
  isHalf,
  isRow,
  isWithIcon,
  isDisabled,
  required,
  meta: { touched, error, warning },
}) => (
  <CCol md="6" lg={isHalf ? `6` : isRow ? `3` : `4`}>
    {!isWithIcon ? (
      <label>
        {required ? (
          <span className="text-danger font-weight-bolder">*</span>
        ) : (
          ""
        )}{" "}
        {labelName}
      </label>
    ) : (
      <label>
        {" "}
        {labelName}
        <i
          className="fal fa-info ml-3 mb-1"
          style={{ cursor: "pointer" }}
          data-for="main"
          data-tip
          data-iscapture="true"
        // onClick={() => { setimage(toAbsoluteUrl(`/${imageName}`)); handleEnter.enter(); }}
        />
      </label>
    )}
    <NumberFormat
      {...input}
      format={"+91 ##### #####"}
      type={type}
      placeholder={labelName}
      disabled={isDisabled}
      className="form-control"
    />
    {touched && error && (
      <h6 className="mb-0" style={{ color: "red", width: "100%" }}>
        {error}
      </h6>
    )}
  </CCol>
);

export function LocationSearchInput(
  {
    input,
    className,
    placeholder,
    containerClasses,
    mutator,
    disabled,
    setaddress,
    setlat,
    setcity,
    setlng,
    setpincode,
    setapartment,
    inputClasses,
    labelClasses,
    labelName,
    meta: { touched, error },
  }) {
  return (<>
    <div>
      <Autocomplete
        {...input}
        className={inputClasses}
        disabled={disabled}
        onChange={(e) => {
          if (!e.target.value) {
            setlat('')
            setlng('')
            setaddress('')
            setcity('')
            setpincode('')
            setapartment('')
          }
          input.onChange(e)
        }}
        // onBlur={input.onBlur}
        // value={input.value}
        placeholder={placeholder}
        apiKey={process.env.REACT_APP_LOCATION_API_KEY}
        options={{
          types: [],
          componentRestrictions: { country: "in" },
          fields: ['ALL']
        }}
        onPlaceSelected={(place) => {
          input.onChange(place?.formatted_address)
          if (place.geometry) {
            setaddress(place?.formatted_address)
            setlat(place.geometry.location.lat())
            setlng(place.geometry.location.lng())
            let tmpCityName = findCityFromGoogleMap(place?.address_components, 'locality')
            let tmpZipCode = findCityFromGoogleMap(place?.address_components, 'postal_code')
            setcity(tmpCityName)
            setapartment(place?.name)
            setpincode(tmpZipCode)
          }
        }}
      />
      <label htmlFor={labelName} className={labelClasses + ' streetlabel '} data-content={labelName}><span className="hidden--visually">{labelName}</span></label>
    </div>
    <div className="input-error">
      {(touched && error) && <span className="mb-0 text-right text-danger" >{error}</span>}
    </div>
  </>)
}