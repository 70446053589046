import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    subAdminList:[],
    subAdminInfo: {},
}

const subAdminReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_SUB_ADMIN_LIST_S:
            return {
                ...state,
                subAdminList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_SUB_ADMIN_LIST_F:
            return {
                ...state,
                subAdminList:[],
            }
        case ACTION_TYPES.ADD_SUB_ADMIN_S:
        case ACTION_TYPES.GET_SUB_ADMIN_S:
        case ACTION_TYPES.EDIT_SUB_ADMIN_S:
            return {
                ...state,
                subAdminInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_SUB_ADMIN_F:
        case ACTION_TYPES.ADD_SUB_ADMIN_F:
        case ACTION_TYPES.EDIT_SUB_ADMIN_F:
        case ACTION_TYPES.DEL_SUB_ADMIN_S:
        case ACTION_TYPES.DEL_SUB_ADMIN_F:
            return {
                ...state,
                subAdminInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }

}

export default subAdminReducer