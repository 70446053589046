import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    faqList:[],
    faqInfo: {},
    faqCategory: [],
}

const faqReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_FAQ_LIST_S:
            return {
                ...state,
                faqList: action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FAQ_CATEGORY_LIST_F:
            return {
                ...state,
                faqCategory:[],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FAQ_CATEGORY_LIST_S:
            return {
                ...state,
                faqCategory:action.payload.data.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FAQ_LIST_F:
            return {
                ...state,
                faqList:null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        case ACTION_TYPES.ADD_FAQ_S:
        case ACTION_TYPES.GET_FAQ_INFO_S:
        case ACTION_TYPES.EDIT_FAQ_S:
            return {
                ...state,
                faqInfo: action.payload.data,
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_FAQ_INFO_F:
        case ACTION_TYPES.ADD_FAQ_F:
        case ACTION_TYPES.EDIT_FAQ_F:
        case ACTION_TYPES.DEL_FAQ_S:
        case ACTION_TYPES.DEL_FAQ_F:
            return {
                ...state,
                faqInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message
            }
        default:
            return state
    }

}

export default faqReducer