import * as ACTION_TYPES from '../constants/types';

const initialState = {
    message: '',
    error: null,
    vehicleList: [],
    vehicleInfo: {},
    activeDriverList: [],
    AssignProductList: [],
    SKUList: [],
    isEdited: false,
    vehicleReportList: []
}

const vehicleReducer = (state = initialState, action) => {

    switch (action.type) {
        case ACTION_TYPES.GET_VEHICLE_LIST_S:
            // console.log(action.payload.data)
            return {
                ...state,
                vehicleList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ACTIVE_DRIVER_F:
            return {
                ...state,
                activeDriverList: [],
                isEdited: false

            }
        case ACTION_TYPES.GET_ACTIVE_DRIVER_S:
            return {
                ...state,
                activeDriverList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null,
                isEdited: false
            }
        case ACTION_TYPES.GET_VEHICLE_LIST_F:
            return {
                ...state,
                vehicleList: [],
            }
        case ACTION_TYPES.GET_ASSIGN_PRODUCT_LIST_S:
            return {
                ...state,
                AssignProductList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_ASSIGN_PRODUCT_LIST_F:
            return {
                ...state,
                AssignProductList: [],
            }
        case ACTION_TYPES.GET_PRODUCT_SKU_S:
            return {
                ...state,
                SKUList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null
            }
        case ACTION_TYPES.GET_PRODUCT_SKU_F:
            return {
                ...state,
                SKUList: [],
            }

        case ACTION_TYPES.ADD_VEHICLE_S:
        case ACTION_TYPES.GET_VEHICLE_INFO_S:
        case ACTION_TYPES.EDIT_VEHICLE_S:
            return {
                ...state,
                vehicleInfo: action.payload.data,
                message: action.payload.message,
                error: null,
                isEdited: false
            }
        case ACTION_TYPES.GET_VEHICLE_INFO_F:
        case ACTION_TYPES.ADD_VEHICLE_F:
        case ACTION_TYPES.EDIT_VEHICLE_F:
        case ACTION_TYPES.ASSIGN_DRIVER_F:
        case ACTION_TYPES.DEL_VEHICLE_S:
        case ACTION_TYPES.DEL_VEHICLE_F:
        case ACTION_TYPES.ADD_PRODUCT_F:
        case ACTION_TYPES.EDIT_PRODUCT_F:
        case ACTION_TYPES.DEL_PRODUCT_F:
            return {
                ...state,
                vehicleInfo: null,
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        case ACTION_TYPES.ASSIGN_DRIVER_S:
        case ACTION_TYPES.ADD_PRODUCT_S:
        case ACTION_TYPES.EDIT_PRODUCT_S:
        case ACTION_TYPES.DEL_PRODUCT_S:
        case ACTION_TYPES.ADD_PRODUCT_QUANTITY_S:
            return {
                ...state,
                message: action.payload.message,
                error: null,
                isEdited: true
            }
        case ACTION_TYPES.API_GET_VEHICLE_REPORT_S:
            return {
                ...state,
                vehicleReportList: action.payload.data?.list ? action.payload.data?.list : [],
                message: action.payload.message,
                error: null,
                isEdited: false
            }
        case ACTION_TYPES.API_GET_VEHICLE_REPORT_F:
            return {
                ...state,
                vehicleReportList: [],
                message: action.payload.data.message,
                error: action.payload.data.message,
                isEdited: false
            }
        default:
            return state
    }

}

export default vehicleReducer