import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function Auth(ComposedComponent) {

    const Authentication = props => {

        const isLoginState = useSelector(state => state.login.isLoggedIn)

        useEffect(() => {

            if (!isLoginState) {
                props.history.push('/')
            }
            

            // eslint-disable-next-line
        }, [isLoginState])

        return <ComposedComponent {...props} />
    }

    return withRouter((Authentication))
}
